import React from "react";
import {
  Navigate,
  Router as BrowserRouter,
  useLocation,
} from "react-router-dom";

import { useHistory } from "src/navigation/useHistory";
import { PagesSwitch } from "src/navigation/Switch";
import { history } from "src/navigation/store";
import { RedirectWithoutTrailingSlash } from "./automaticRedirect";
import redirects from "./redirects.json";

const RedirectHandler = () => {
  const { pathname } = useLocation();
  const redirect = redirects.find(r => r.current === pathname);

  if (redirect) {
    if (pathname.includes("/blog/")) {
      window.location.href = "https://nutritionpro.cz/blog/";
      return null; // Ensure no further rendering happens after redirect
    } else if (pathname.includes("/jobs/")) {
      window.location.href = "https://jobs.nutritionpro.cz/";
      return null;
    } else if (pathname.includes("/partner-program")) {
      window.location.href = "https://partners.nutritionpro.cz/";
      return null;
    } else {
      return <Navigate to={redirect.redirect} />;
    }
  }

  return null; // or render a 404 component, etc.
};


export const Router: React.FC = () => {
  const { location, action } = useHistory();

  const mode = "guest";

  return (
    <BrowserRouter
      location={location}
      navigationType={action}
      navigator={history}
    >
      <RedirectHandler />
      <RedirectWithoutTrailingSlash>
        <PagesSwitch mode={mode} />
      </RedirectWithoutTrailingSlash>
    </BrowserRouter>
  );
};
